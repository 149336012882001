import { ReactElement } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  createTheme,
  // responsiveFontSizes,
} from '@mui/material/styles';

import themeColor from 'theme-loader/theme';

/**
 *  Theme Loader Component
 */
export default function ThemeLoader(props: { children: ReactElement }) {
  const theme = createTheme(themeColor);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
