import React from 'react';

import ReactDOM from 'react-dom/client';
import 'index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'App';
import reportWebVitals from 'reportWebVitals';
import ThemeLoader from 'theme-loader/ThemeLoader';

import { store } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeLoader>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </ThemeLoader>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
