import React from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import useAuth, { AuthProvider, AuthRedirect } from 'context/AuthContext';
import ForgetPassword from 'pages/sign-up/ForgetPassword';

const SignIn = React.lazy(() => import('pages/sign-in/SignIn'));
const SignUp = React.lazy(() => import('pages/sign-up/SignUp'));
const VerifyPassword = React.lazy(() => import('pages/sign-up/VerifyPassword'));
const SignUpActivationCode = React.lazy(
  () => import('pages/sign-up/SignUpActivationCode')
);
const Dashboard = React.lazy(() => import('pages/dashboard/Dashboard'));
const CreateAShipment = React.lazy(
  () => import('pages/create-a-shipment/CreateAShipment')
);
const QuickShip = React.lazy(() => import('pages/quick-ship/QuickShip'));
const History = React.lazy(() => import('pages/history/History'));
const Support = React.lazy(() => import('pages/support/Support'));
const Profile = React.lazy(() => import('pages/profile/Profile'));

/**
 * Main App routing that maps route with respective component
 */
export const AppRouting = () => {
  const auth = useAuth();
  const defaultNavigate = (
    <Navigate to={auth?.authenticated ? './dashboard' : './sign-in'} />
  );

  /**
   * Function that puts loader while component gets loaded
   */

  /**
   * Defines Route with specific wrapper and sub-routes
   */
  const routing = useRoutes([
    {
      path: '/',
      element: <AuthRedirect />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: '/dashboard',
          element: <Dashboard />,
        },
        {
          path: '/create-a-shipment',
          element: <CreateAShipment />,
        },
        {
          path: '/quick-ship',
          element: <QuickShip />,
        },
        {
          path: '/history',
          element: <History />,
        },
        {
          path: '/support',
          element: <Support />,
        },
        {
          path: '/profile',
          element: <Profile />,
        },
        {
          path: '/sign-up',
          element: <SignUp />,
        },
        {
          path: '/verify-code',
          element: <SignUpActivationCode />,
        },
        {
          path: '/verify-password/:resetCode',
          element: <VerifyPassword />,
        },
        {
          path: '/reset-password/:resetCode',
          element: <VerifyPassword isReset={true} />,
        },
        {
          path: '/sign-in',
          element: <SignIn />,
        },
        {
          path: '/forgot-password',
          element: <ForgetPassword />,
        },
        {
          index: true,
          element: defaultNavigate,
        },
        {
          path: '*',
          element: defaultNavigate,
        },
      ],
    },
  ]);

  return (
    <AuthProvider>
      <>{routing}</>
    </AuthProvider>
  );
};
