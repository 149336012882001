import * as yup from 'yup';

/**
 * Common validations
 */
const validationSchemas = {
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .max(255, 'Email should not exceed more than 255 characters')
    .required('Email is required')
    .matches(
      /^([a-z][a-z0-9_]*|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/,
      'Enter valid email'
    ),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  fullName: yup
    .string()
    .trim()
    .required('Full name is required')
    .matches(/[aA-zZ\s]+$/, 'Enter valid name'),
  companyName: yup
    .string()
    .trim()
    .required('Company name is required')
    .matches(
      /^[a-zA-Z0-9_. -]*$/,
      'Only alphabets are allowed for this field '
    ),
  firstName: yup
    .string()
    .trim()
    .required('First name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  phone: yup
    .string()
    .required('Phone is required')
    .typeError('Only number is allowed')
    .matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      'Enter a valid Phone'
    ),
  address: yup.string().trim(),
  province: yup
    .string()
    .trim()
    .required('Province is required')
    .matches(/^[aA-zZ\s-]+$/, 'Only alphabets are allowed for this field '),
  city: yup
    .string()
    .trim()
    .required('City is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  postalCode: yup
    .string()
    .trim()
    .required('Postal code is required')
    .matches(/^[a-zA-Z0-9_.-\s]*$/, 'Postal code is not valid'),
  country: yup.string().required('Country is required'),
  cardNumber: yup
    .string()
    .required('Card number is required')
    .matches(
      /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/,
      'Enter valid card number'
    ),
  validThrough: yup
    .string()
    .required('Valid through date is required')
    .matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, 'Enter valid expiration date'),
  cvv: yup
    .string()
    .required('CVV is required')
    .matches(/^[0-9]{3,4}$/, 'Enter valid CVV'),
};

export default validationSchemas;
