import 'App.css';
import { AppRouting } from 'AppRouting';
import PreventBack from 'components/prevent-back/PreventBack';
import CustomizedSnackbar from 'components/snackbar/CustomizedSnackbar';

function App() {
  return (
    <>
      <AppRouting />
      <CustomizedSnackbar />
      <PreventBack />
    </>
  );
}

export default App;
