import type { Action } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { CombinedState as T } from '@reduxjs/toolkit/dist/query/core/apiState';
import type { CombinedState } from 'redux';

import apiInstance from 'store/apis/createApiInstance';

/**
 * Main reducer which combines all reducers together
 */
const rootReducer = combineReducers({
  [apiInstance.reducerPath]: apiInstance.reducer,
});
const reducer = (
  state:
    | CombinedState<{
        api: T<{}, string, 'api'>;
      }>
    | undefined,
  action: Action
) => {
  if (action.type === 'logout') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

/**
 * Configured redux store with middleware
 */
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiInstance.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
