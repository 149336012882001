import React, { useState, Fragment, useMemo } from 'react';

//import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Outlet, useNavigate } from 'react-router-dom';

import SideBar, { drawerWidth } from 'components/common/side-bar/SideBar';
import useAuth from 'context/AuthContext';
import { useGetProfileQuery } from 'store/apis/userApis';
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

/**
 * Defines the Top App bar styles
 */
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.common.white,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
  ...(!open && {
    width: `calc(100% - ${theme.spacing(7)})`,
    [theme.breakpoints.between('xs', 'md')]: {
      width: '100%',
    },
  }),
}));

/**
 * Renders Layout for all authenticated Pages
 */
export default function AuthLayout() {
  const [open, setOpen] = useState(true);
  const auth = useAuth();
  const navigate = useNavigate();
  const { data: userProfile } = useGetProfileQuery(1);
  const { initials, fullName } = useMemo(() => {
    let name = `${userProfile?.firstName?.charAt?.(
      0
    )}${userProfile?.lastName?.charAt?.(0)}`;
    if (name?.length !== 2) {
      name = userProfile?.firstName?.substr?.(0, 2);
    }
    return {
      fullName: `${userProfile?.firstName || ''} ${
        userProfile?.lastName || ''
      }`,
      initials: name?.toUpperCase?.(),
    };
  }, [userProfile]);

  return (
    <Box>
      <SideBar open={open} />
      <AppBar open={open} position="fixed">
        <Toolbar>
          <Stack
            width={'100%'}
            direction={'row'}
            justifyContent="space-between"
            alignItems={'center'}
          >
            <IconButton onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton>
            <PopupState variant="popper">
              {(popupState) => (
                <Fragment>
                  <Tooltip title={fullName}>
                    <IconButton {...bindTrigger(popupState)} size="small">
                      <Avatar>{initials}</Avatar>
                    </IconButton>
                  </Tooltip>

                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      sx={{ justifyContent: 'center' }}
                      onClick={popupState.close}
                    >
                      <Typography variant="h6">{fullName}</Typography>
                    </MenuItem>
                    <Divider sx={{ m: 1 }} />
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        navigate('/profile');
                      }}
                      sx={{ justifyContent: 'center' }}
                    >
                      Profile
                    </MenuItem>
                    <Divider sx={{ m: 1 }} />
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        auth.removeAuth();
                      }}
                      sx={{ justifyContent: 'center' }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Fragment>
              )}
            </PopupState>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          transition: (theme) =>
            theme.transitions.create(['margin-left', 'left'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          ml: {
            xs: open ? `${drawerWidth}px` : '0',
            md: open ? `${drawerWidth}px` : '68px',
          },
          p: 4,
          mt: '64px',
        }}
      >
        <React.Suspense
          fallback={
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Outlet />
        </React.Suspense>
      </Box>
    </Box>
  );
}
