const config = {
  version: 'v1.0.16',
  envName: process.env.REACT_APP_ENV_NAME,
  baseUrl: process.env.REACT_APP_BASE_URL,
  stripe: process.env.REACT_APP_STRIPE_KEY,
};
console.log('baseUrl', config.baseUrl, config.version);
// var testData: any = {
//   baseUrl: config.baseUrl,
//   version: config.version,
// };

export default config;
