import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Outlet } from 'react-router-dom';

import config from 'config';

/**
 * Renders the Layout for all Sign In and Sign Up pages
 */
function SignInLayout() {
  return (
    <Stack
      sx={{
        height: '100%',
        backgroundColor: 'primary.main',
      }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <React.Suspense
        fallback={
          <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        }
      >
        <Outlet />
      </React.Suspense>
      {config.envName === 'development' && (
        <Typography
          sx={{
            position: 'absolute',
            bottom: 10,
            left: 10,
          }}
          color="white"
        >
          {config.version}
        </Typography>
      )}
    </Stack>
  );
}

export default SignInLayout;
