const fontSize = 14; // px
export const htmlFontSize = 16;
const coef = fontSize / 14;

const fontSizeCalc = (expectedBodyFontSize: number) => {
  return (16 / 16) * expectedBodyFontSize;
};

/**
 * Function that converts given pixel value into Rem
 */
export const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`;

/**
 * Defines typography configuration
 */
export const themeTypography = {
  htmlFontSize,
  fontSize: fontSizeCalc(fontSize),
  fontFamily: ['Inter'].join(','),
};
