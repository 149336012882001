import jwt_decode from 'jwt-decode';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import { globalVariable } from './globalVariables';

/**
 * Function that converts given color into hex color
 */
export const stringToColor = (string: string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

/**
 * Function that accepts username to create Profile Icon with style and name
 */
export const stringAvatar = (name: string) => {
  return {
    style: {
      backgroundColor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};

/**
 * Function that generates decoded token
 */
export const jwtDecode = (t: string | any) => {
  if (t.includes('.')) {
    let token: {
      raw: string | null;
      header: Record<string, any> | null;
      payload: Record<string, any> | null;
    } = {
      raw: null,
      header: null,
      payload: null,
    };
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return token;
  }
  return t;
};
interface ParseData {
  companyId: string | undefined;
  email: string | undefined;
  exp: number | undefined;
  firstName: string | undefined;
  fullName: string | undefined;
  iat: number | undefined;
  lastName: string | undefined;
  preferredLockerId: string | undefined;
  provider: string | undefined;
  role: string | undefined;
  uuid: string | undefined;
  _id: string | undefined;
}

export const parseDataFromToken = (): ParseData | string => {
  try {
    const token = getCookie('token');
    if (token) {
      return jwt_decode(token);
    }
  } catch (error) {
    console.error(error);
    return '';
  }
  return '';
};

export const getPreferredLockerId = (): string => {
  try {
    const tokenData = parseDataFromToken();
    if (typeof tokenData === 'object') {
      if (tokenData?.preferredLockerId) {
        return tokenData.preferredLockerId;
      }
    }
  } catch (error) {
    console.error(error);
    return '';
  }
  return '';
};

export const getUserId = (): string => {
  try {
    const tokenData = parseDataFromToken();
    if (typeof tokenData === 'object') {
      if (tokenData?._id) {
        return tokenData._id;
      }
    }
  } catch (error) {
    console.error(error);
    return '';
  }
  return '';
};

export const updateGlobalVariables = () => {
  globalVariable.currentUserId = getUserId();
  globalVariable.currentPreferredLockerId = getPreferredLockerId();
};
/**
 * Function that sets cookies value using token
 */
export const setCookie = (cname: string, cvalue: string) => {
  if (cvalue) {
    const jwtData = jwtDecode(cvalue);
    const d = jwtData.payload.exp
      ? new Date(jwtData?.payload?.exp * 1000).toUTCString()
      : new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toUTCString();
    const expires = 'expires=' + d;
    document.cookie =
      cname + '=' + btoa(JSON.stringify(cvalue)) + ';' + expires + ';path=/';
    if (cname === 'token') {
      updateGlobalVariables();
    }
  }
};

/**
 * Function that fetches cookie value
 */
export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0 && name.length !== c.length) {
      return atob(c.substring(name.length, c.length));
    }
  }
  return '';
};

export const getToken = () => {
  return getCookie('token') ? JSON.parse(getCookie('token')) : false;
};
/**
 * Function to delete cookie value
 */
export const deleteCookie = (cname: string) => {
  document.cookie = cname + '=; Path=/;max-age=0';
};

/**
 * Function to delete all cookie value
 */
export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    deleteCookie(name);
  }
};

/**
 * Function to add debounce
 */
let timeout: string | number | NodeJS.Timeout | undefined;
export const debounce = (callback: () => void, delay: number) => {
  clearTimeout(timeout);
  timeout = setTimeout(callback, delay);
};

/**
 * Function to generate formatted query params
 */
export const queryParamsBuilder = (query: Record<string, any>) => {
  if (typeof query !== 'object') {
    return '';
  }
  const keys = Object.keys(query).filter(
    (b) => query[b] !== null && query[b] !== ''
  );
  if (keys.length) {
    return (
      '?' +
      new URLSearchParams(
        keys.reduce((a: Record<string, any>, b) => {
          a[b] = query[b];
          return a;
        }, {})
      ).toString()
    );
  }
  return '';
};

/**
 * Function to update cache data
 */
export function updateCacheData(
  {
    state,
    method,
    dispatch,
    extendedApi,
  }: {
    method: string;
    state: Record<string, any>;
    dispatch: ThunkDispatch<any, any, AnyAction>;
    extendedApi: { util: { updateQueryData: Function } };
  },

  cb: Function
) {
  const { api } = state;
  let cacheArgToUpdate: (string | undefined)[] = [undefined];
  if (api) {
    cacheArgToUpdate = Object.keys(api.queries)
      .filter(
        (d) =>
          d.indexOf(`${method}(`) === 0 &&
          Object.keys(api.queries[d]).length > 0
      )
      .map((d) => api.queries[d].originalArgs);
  }
  return cacheArgToUpdate.forEach((originalArgs) =>
    dispatch(extendedApi.util.updateQueryData(method, originalArgs, cb))
  );
}
export const b64toBlob = (
  b64Data = '',
  contentType = 'application/pdf',
  sliceSize = 512
) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
