import { useEffect, useState, forwardRef } from 'react';

import { Button } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

import { newEvent } from 'utils/eventEmitter';

/**
 Types of Snackbar
1. success
2. error
3. warning
4. info
 */

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function PreventBack() {
  const [shouldPreventBack, setShouldPreventBack] = useState(false);
  const [showAlert, setShowAlert] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    const preventUnload = (value: boolean) => {
      setShouldPreventBack(value);
    };

    const handlePopstate = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.pushState(null, window.location.pathname);
      // setShowAlert({
      //   type: 'back',
      //   message:
      //     'Use the "Back" button on the page to avoid losing your progress.',
      // });
      return event;
    };
    newEvent.on('preventBack', preventUnload);
    newEvent.on('popupState', handlePopstate);
    return () => {
      newEvent.off('preventBack', preventUnload);
      newEvent.on('popupState', handlePopstate);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = 'Do not refresh the page, your progress will be lost';
      return event;
    };
    const handlePopstate = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.pushState(null, window.location.pathname);
      if (window.confirm('Changes you made may not be saved.')) {
        navigate('/');
      }
      // setShowAlert({
      //   type: 'back',
      //   message:
      //     'Use the "Back" button on the page to avoid losing your progress.',
      // });
      return '';
    };
    if (shouldPreventBack) {
      window.history.pushState(null, window.location.pathname);
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('popstate', handlePopstate);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [shouldPreventBack, navigate]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={!!showAlert?.message}
        onClose={() => setShowAlert({})}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert
          action={
            <>
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert({});
                }}
              >
                Okay
              </Button>
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert({});
                  navigate('/');
                }}
              >
                Cancel
              </Button>
            </>
          }
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
