import type { ThemeOptions } from '@mui/material';

import { LIGHT } from 'theme-loader/theme/colors';
import { pxToRem, themeTypography } from 'theme-loader/theme/typography';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
      success: string;
      primary: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
      success?: string;
      primary: string;
    };
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    shadow: true;
  }
}

const theme: ThemeOptions = {
  palette: LIGHT,
  typography: themeTypography,
  status: {
    danger: '#e48d8d',
    success: '#97d69d',
    primary: LIGHT.primary.main,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        // disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: pxToRem(18),
          textTransform: 'none',
        },
      },
    },

    // MuiTypography: {
    //   styleOverrides: {
    //     root: {
    //       color: '#848484',
    //     },
    //   },
    // },
    MuiPaper: {
      defaultProps: {
        // elevation: 1,
        // disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'shadow' },
          style: {
            borderRadius: '10px',
            boxShadow: '0px 1px 4px 0px #4E8AFF40',
            border: '1px solid #00000040',
          },
        },
      ],
      styleOverrides: {
        root: {
          border: '1px solid #f1f6ff',
        },
      },
    },
  },
};
export default theme;
