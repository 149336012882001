import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';

import LogoOnly from 'assets/img/logo_only.png';
import Dashboard from 'assets/svg/dashboard.svg';
import Support from 'assets/svg/help.svg';
import History from 'assets/svg/history.svg';
import QuickShip from 'assets/svg/quick_ship.svg';
import CreateShipment from 'assets/svg/ship.svg';
import config from 'config';

/**
 * side menu list
 */

const menuList = [
  {
    route: '/dashboard',
    name: 'Dashboard',
    image: Dashboard,
  },
  {
    route: '/create-a-shipment',
    name: 'Create a Shipment',
    image: CreateShipment,
  },
  {
    route: '/quick-ship',
    name: 'Quick Ship',
    image: QuickShip,
  },
  {
    route: '/history',
    name: 'History',
    image: History,
  },
  {
    route: '/support',
    name: 'Support',
    image: Support,
  },
];

/**
 * Side bar Drawer width
 */
export const drawerWidth = 260;

/**
 * Side bar Drawer styles when it's opened
 */
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

/**
 * Side bar Drawer styles when it's closed
 */
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

/**
 * Side bar Drawer styles
 */
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  'width': drawerWidth,
  'flexShrink': 0,
  'whiteSpace': 'nowrap',
  'boxSizing': 'border-box',
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.primary.main,
    border: 0,
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

/**
 * Side bar Drawer component contains logo and list of menus
 */
const SideBar = ({ open }: { open: boolean | undefined }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        display: { xs: open ? '' : 'none', sm: open ? '' : 'none', md: 'flex' },
      }}
    >
      <Stack height={'100%'}>
        <Stack
          direction={'row'}
          onClick={() => navigate('/')}
          sx={{ cursor: 'pointer' }}
        >
          <Box
            component={'img'}
            src={LogoOnly}
            p={1.5}
            sx={{
              minHeight: '66px',
              height: 'auto',
              width: '66px',
              transition: (theme) =>
                theme.transitions.create('padding', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
            }}
            alt="logo"
          />
          <Stack>
            <Typography variant="h5" mt={1} fontWeight="700" color="white">
              ParcelPort
            </Typography>
            <Typography
              variant="body2"
              fontWeight="700"
              textAlign={'end'}
              color="white"
              mt={-0.4}
            >
              Courier Portal
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <List>
          {menuList.map((item, index) => (
            <ListItem
              onClick={() => navigate(item.route)}
              key={item.route}
              disablePadding
              sx={{
                display: 'block',
                color: (theme) => theme.palette.common.white,
                backgroundColor: (theme) =>
                  location.pathname.indexOf(item.route) > -1 ||
                  (location.pathname === '/' && index === 0)
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main,
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: (theme) => theme.palette.common.white,
                  }}
                >
                  <img src={item.image} alt="logo" />
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {location.pathname.indexOf(item.route) > -1 && open ? (
                  <ListItemIcon
                    sx={{
                      justifyContent: 'flex-end',
                      color: (theme) => theme.palette.common.white,
                    }}
                  >
                    <NavigateNextIcon />
                  </ListItemIcon>
                ) : null}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {config.envName === 'development' && (
          <Typography
            sx={{
              position: 'absolute',
              bottom: 10,
              left: 10,
            }}
            color="white"
          >
            {config.version}
          </Typography>
        )}
      </Stack>
    </Drawer>
  );
};

export default SideBar;
