import { EventEmitter } from 'events';

export const newEvent = new EventEmitter();

export interface SnackData {
  open?: boolean;
  type?: 'success' | 'error' | 'info' | 'warning';
  message?: string;
  id?: number;
}

export const showToast = (data: SnackData) => {
  newEvent.emit('showToast', data);
};

export const emitLogout = () => {
  newEvent.emit('logout');
};

export const shouldPreventBack = (shouldPrevent: boolean) => {
  newEvent.emit('preventBack', shouldPrevent);
};
