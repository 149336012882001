// import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import onlyLogo from 'assets/img/only_logo.png';
import { pxToRem } from 'theme-loader/theme/typography';
interface Props {
  title: string;
}

/**
 * Common header used in all Sign Up and Sign In pages
 */

export const SignUpHeader = ({ title }: Props) => {
  return (
    <Stack justifyContent={'center'} alignItems={'center'}>
      <Box
        component={'img'}
        py={2}
        src={onlyLogo}
        alt="logo"
        height={pxToRem(98)}
      />
      <Typography
        // variant="h6"

        sx={{
          background: (theme) => theme.palette.grey[200],
          width: '100%',
          color: 'black',
        }}
        py={0.5}
        align="center"
        fontWeight={'fontWeightBold'}
      >
        {title}
      </Typography>
    </Stack>
  );
};
