import React, { forwardRef, useEffect, useState } from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import { SnackData, newEvent } from 'utils/eventEmitter';

/**
 Types of Snackbar
1. success
2. error
3. warning
4. info
 */

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbar() {
  const [snackData, setSnackData] = useState<SnackData>({});

  const handleSnackData = (data: SnackData) => {
    setSnackData({ ...data, id: Math.random() });
  };

  useEffect(() => {
    newEvent.on('showToast', handleSnackData);
    return () => {
      newEvent.off('showToast', handleSnackData);
    };
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (snackData?.open) {
      timer = setTimeout(() => {
        handleClose();
      }, 3000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [snackData?.open, snackData?.id]);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    // when click outside the snackbar it won't close snackbar
    if (reason === 'clickaway') {
      return;
    }

    setSnackData((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={snackData.open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert
          onClose={handleClose}
          severity={snackData.type}
          sx={{ width: '100%', border: 'none' }}
        >
          {snackData.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
