import { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import CustomLoaderButton from 'components/action-button/CustomLoaderButton';
import HookTextField from 'components/common/hook-form-fields/HookTextField';
import { SignUpHeader } from 'components/sign-up-header/SignUpHeader';
import { useForgetPasswordMutation } from 'store/apis/authenticationApis';
import { showToast } from 'utils/eventEmitter';
import validationSchemas from 'utils/validationSchemas';

const validationSchema = yup.object({
  email: validationSchemas.email,
});

/**
 * Forgot page component having email input
 */

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [handleForgetPassword, { isLoading }] = useForgetPasswordMutation();
  const { handleSubmit, control } = useForm<FieldValues>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      const payload = {
        email: data?.email,
        passwordResetURL: `${window.location.origin}/reset-password/`,
      };
      const response: any = await handleForgetPassword(payload);
      if (response?.error) {
        showToast({
          open: true,
          type: 'error',
          message:
            response?.error?.data?.message ??
            'Something went wrong, Please contact to ParcelPort team!',
        });
      } else if (response?.data) {
        showToast({
          open: true,
          type: 'success',
          message:
            response?.data?.message ?? 'Password reset link sent successfully',
        });
        navigate('/sign-in');
      }
    },
    [handleForgetPassword, navigate]
  );
  return (
    <Paper
      sx={{
        width: '420px',
        maxWidth: '100%',
      }}
      elevation={10}
    >
      <SignUpHeader title={'Forgot Password'} />
      <Box p={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Stack spacing={2}>
              <HookTextField
                control={control}
                name="email"
                label="Enter Email Address"
              />
            </Stack>
            <Stack spacing={2} alignItems="flex-end">
              <CustomLoaderButton type="submit" loading={isLoading}>
                Send Reset Email
              </CustomLoaderButton>
              <Typography
                py={5}
                width={'100%'}
                align="center"
                color={(theme) => theme.palette.text.primary}
              >
                Already have an account?{' '}
                <Link
                  sx={{ cursor: 'pointer' }}
                  underline="none"
                  color="secondary"
                  onClick={() => navigate('/sign-in')}
                >
                  Sign In
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Paper>
  );
};
export default ForgetPassword;
