import { FieldValues } from 'react-hook-form';

import createApiInstance from './createApiInstance';

const authApis = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (body: FieldValues) => {
        return {
          url: 'auth/resend-code',
          method: 'POST',
          body: body,
        };
      },
    }),

    verifyCode: builder.mutation({
      query: (resetCode: FieldValues) => {
        return {
          url: `auth/confirm/${resetCode}`,
          method: 'GET',
        };
      },
    }),

    setPassword: builder.mutation({
      query: (body: FieldValues) => {
        return {
          url: 'user/change-password',
          method: 'Post',
          body: body,
        };
      },
    }),

    signIn: builder.mutation({
      query: (body: FieldValues) => {
        return {
          url: '/auth/login',
          method: 'POST',
          body: body,
        };
      },
    }),

    forgetPassword: builder.mutation({
      query: (body: FieldValues) => {
        return {
          url: 'user/reset-password',
          method: 'POST',
          body: body,
        };
      },
    }),

    // this is use for forget password link wether it expire or not
    verifyForgetPasswordCode: builder.query({
      query: (resetCode: string | any) => {
        return {
          url: `user/verify-reset-code/${resetCode}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useSignUpMutation,
  useVerifyCodeMutation,
  useSetPasswordMutation,
  useSignInMutation,
  useForgetPasswordMutation,
  useVerifyForgetPasswordCodeQuery,
} = authApis;
