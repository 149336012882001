export const primaryColor = {
  100: '#E5EEFF',
  200: '#D9E6FF',
  300: '#CCDDFF',
  400: '#BFD4FF',
  500: '#A6C3FF',
  600: '#8CB2FF',
  700: '#80AAFF',
  800: '#6699FF',
  900: '#4e8aff',
  1000: '#4582f9',
};

/**
 * Secondary color shades
 */
export const secondaryColor = {
  100: '#FFF3ED',
  200: '#FFDCCA',
  300: '#FFD1B8',
  400: '#FEBA94',
  500: '#FEAE83',
  600: '#FE975F',
  700: '#FE8B4D',
  800: '#FE6918',
  900: '#FE5E05',
};

export const LIGHT = {
  primary: {
    main: primaryColor[900],
    light: primaryColor[400],
    dark: primaryColor[1000],
  },
  secondary: {
    main: secondaryColor[900],
    light: secondaryColor[400],
    dark: secondaryColor[900],
  },
  grey: {
    50: '#ececec',
    100: '#d1d5db',
    200: '#9CA3AF69',
    300: '#c5c6c7',
  },
  text: {
    primary: '#686a6c',
  },
  type: 'Light',
};
