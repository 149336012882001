import { BaseTextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import type { Theme, SxProps } from '@mui/system';
// import type { Control } from 'react-hook-form';
import { useController, UseControllerProps } from 'react-hook-form';

/**
 * Common component for text input
 */
interface TextProp extends BaseTextFieldProps {
  label?: string;
  sx?: SxProps<Theme>;
  InputProps?: object;
  handleInputChange?: (e: any) => void;
}
export default function HookTextField({
  handleInputChange,
  ...props
}: TextProp & UseControllerProps) {
  const { field, fieldState, formState } = useController(props);
  return (
    <TextField
      fullWidth
      {...field}
      {...props}
      onChange={(e) => {
        field.onChange(e);
        handleInputChange?.(e);
      }}
      error={
        (fieldState?.isTouched || formState?.isSubmitted) &&
        Boolean(fieldState?.error)
      }
      helperText={
        (fieldState?.isTouched || formState?.isSubmitted) &&
        fieldState?.error?.message
      }
    />
  );
}
