import { USER } from 'models/user';
import { globalVariable } from 'utils/globalVariables';

import createApiInstance from './createApiInstance';

const paymentApis = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => {
        const _id = globalVariable.currentUserId;
        return {
          url: `users/${_id}`,
          method: 'GET',
        };
      },
      providesTags: ['UserProfile'],
    }),
    userInfoUpdate: builder.mutation({
      query: (body: any) => {
        const _id = globalVariable.currentUserId;
        return {
          url: `/users/${_id}`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: ['UserProfile'],
    }),

    savedCard: builder.query({
      query: () => {
        const _id = globalVariable.currentUserId;
        return {
          url: `user/${_id}/cards`,
          method: 'GET',
        };
      },
      providesTags: ['SavedCard'],
    }),

    editCard: builder.mutation({
      query: (body: USER.EditCardBody) => {
        const _id = globalVariable.currentUserId;
        return {
          url: `user/${_id}/card`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: ['SavedCard'],
    }),

    deleteCard: builder.mutation({
      query: (cardId: string) => {
        const _id = globalVariable.currentUserId;
        return {
          url: `user/${_id}/card/${cardId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['SavedCard'],
    }),

    getRecipientsList: builder.query({
      query: () => {
        const _id = globalVariable.currentUserId;
        return {
          url: `/outbound-recipients/${_id}`,
          method: 'GET',
        };
      },
      providesTags: ['AddressBook'],
    }),
  }),
});

export const {
  useSavedCardQuery,
  useUserInfoUpdateMutation,
  useEditCardMutation,
  useDeleteCardMutation,
  useGetRecipientsListQuery,
  useGetProfileQuery,
  useLazyGetProfileQuery,
} = paymentApis;
