import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  variant?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: () => void;
}

const CustomLoaderButton = ({
  loading,
  onClick,
  children,
  type,
  variant,
}: Props) => {
  return (
    <Box sx={{ position: 'relative' }} width={'100%'}>
      <Button
        variant={'contained'}
        disabled={loading}
        onClick={onClick}
        type={type}
        fullWidth
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};

export default CustomLoaderButton;
