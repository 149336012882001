import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Navigate, matchRoutes } from 'react-router-dom';

import AuthLayout from 'layouts/AuthLayout';
import SignInLayout from 'layouts/SignInLayout';
import { AppDispatch } from 'store';
import { useLazyGetProfileQuery } from 'store/apis/userApis';
import { newEvent } from 'utils/eventEmitter';

import {
  deleteAllCookies,
  getToken,
  setCookie,
  updateGlobalVariables,
} from '../utils/commonFunctions';
interface authContext {
  authenticated: string | null | undefined;
  setAuth: ({ token }: { token: string }) => void;
  removeAuth: () => void;
}

/**
 * Accepts stored token from local storage
 */
// const token = localStorage.getItem('token');
const token = getToken();

if (token) {
  updateGlobalVariables();
}
/**
 * Creates context for authentication
 */
const AuthContext = React.createContext<authContext>({
  authenticated: null,
  setAuth: function () {},
  removeAuth: function () {},
});

/**
 * Provides authentication for authenticated pages
 */
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch<AppDispatch>();
  const [getProfile, { data, isLoading }] = useLazyGetProfileQuery();
  const [auth, setAuth] = useState<string | null>(token);

  useEffect(() => {
    if (auth && !data?._id) {
      getProfile(1);
    }
  }, [getProfile, data, auth]);

  /**
   * Removes authentication when Logging out
   */
  const removeAuth = useCallback(() => {
    dispatch({ type: 'logout' });
    deleteAllCookies();
    setAuth(null);
  }, [dispatch]);

  useEffect(() => {
    newEvent.on('logout', removeAuth);
    return () => {
      newEvent.off('logout', removeAuth);
    };
  }, [removeAuth]);

  /**
   * Stores authenticated token when Signing in
   */
  const addAuth = useCallback(({ token }: { token: string }) => {
    setCookie('token', token);
    // localStorage.setItem('token', token);
    setAuth(token);
  }, []);

  const value = {
    authenticated: auth,
    setAuth: addAuth,
    removeAuth,
  };
  if (isLoading) {
    return null;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

/**
 * Returns auth data from context
 */
export default function useAuth() {
  return React.useContext(AuthContext);
}

/**
 * If user is not authenticated the redirects to Sign In page otherwise respective route page
 */
const nonAuthPages = [
  '/sign-up',
  '/verify-code',
  '/verify-password/:resetCode',
  '/reset-password/:resetCode',
  '/sign-in',
  '/forgot-password',
];
export const AuthRedirect = (): React.ReactElement | null => {
  const auth = useAuth();
  const location = useLocation();
  const authenticatedRoute = !matchRoutes(
    nonAuthPages.map((path) => ({
      path,
    })),
    location.pathname
  );

  if (!auth?.authenticated && authenticatedRoute) {
    return <Navigate to="/sign-in" state={{ from: location }} />;
  } else if (auth?.authenticated && !authenticatedRoute) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }

  return authenticatedRoute && auth?.authenticated ? (
    <AuthLayout />
  ) : (
    <SignInLayout />
  );
};
